/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as ServiceWorkerScriptStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';

import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class ServiceWorkerScriptState implements ServiceWorkerScriptStateModule.ServiceWorkerScriptState {
        static objectStoreDexieSchema: string = "&[appId+id], appId, id";

        appId!: string;
        id!: string;
        importmapEntry: IServiceWorkerImportMapEntry;

        constructor(options: {
            appId: string;
            id: string;
            url: string | URL;
            importmapEntry: IServiceWorkerImportMapEntry;
        }) {
            this.appId = options.appId;
            this.id = options.id;
            this.importmapEntry = options.importmapEntry;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateServiceWorkerScriptState(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
        
            await IndexedDBHandler.deleteServiceWorkerScriptState(this);
        }

        public async forceReload(): Promise<ServiceWorkerScriptState | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            return await IndexedDBHandler.getServiceWorkerScriptStateFromIndexedDB(this.appId, this.id);
        }
    }

    self.o365.exportScripts({ ServiceWorkerScriptState });
})();
